@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  @font-face {
    font-family: "grifterbold";
    src: url("../public/assets/fonts/grifterbold.otf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "jtmw";
    src: url("../public/assets/fonts/jtmw.otf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "apercu";
    src: url("../public/assets/fonts/apercu-pro-regular.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "apercu";
    src: url("../public/assets/fonts/apercu-pro-medium.otf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "apercu";
    src: url("../public/assets/fonts/apercu-pro-light.otf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "apercu";
    src: url("../public/assets/fonts/apercu-pro-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "brandon";
    src: url("../public/assets/fonts/brandon-thin.otf");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "brandon";
    src: url("../public/assets/fonts/brandon-light.otf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "brandon";
    src: url("../public/assets/fonts/brandon-regular.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "brandon";
    src: url("../public/assets/fonts/brandon-medium.otf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "brandon";
    src: url("../public/assets/fonts/brandon-bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "brandon";
    src: url("../public/assets/fonts/brandon-black.otf");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  /* For debugging of CSS */
  /* * {
    outline: 1px solid limegreen !important;
  } */
}
